<template>
  <div class="w-50">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="Enter route:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="form.route"
          placeholder="Enter route"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-checkbox v-model="checked" name="check-button" switch>
        Switch Checkbox <b>(Upload: {{ checked }})</b>
      </b-form-checkbox>

      <b-form-group id="input-group-2" label="Enter name:" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.name"
          placeholder="Enter name"
          :required="!checked"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Upload File:"
        label-for="input-3"
        v-if="checked"
      >
        <b-form-file
          id="input-3"
          v-model="form.file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          required
        ></b-form-file>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>
import UploadService from "@/views/commons/configurations/upload/services/index.js";
export default {
  data() {
    return {
      form: {
        route: "",
        name: "",
        file: null,
      },
      show: true,
      checked: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.checked) {
        this.uploadFile();
      } else {
        this.downloadFile();
      }
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("route", this.form.route);
      formData.append("name", this.form.name);
      try {
        await UploadService.upload(formData);
        this.form.file = null;
        this.$bvToast.toast("File uploaded successfully", {
          title: "Success",
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast("An error occurred", {
          title: "Error",
          variant: "danger",
        });
      }
      // Send the form data to the server
    },
    async downloadFile() {
      // Send the form data to the server
      try {
        const data = await UploadService.download({
          route: `${this.form.route}/${this.form.name}`,
        });
        this.forceFileDownload(data.data, this.form.name);

        this.$bvToast.toast("File downloaded successfully", {
          title: "Success",
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast("An error occurred", {
          title: "Error",
          variant: "danger",
        });
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.route = "";
      this.form.name = "";
      this.form.file = null;
      this.form.checked = ["upload"];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>