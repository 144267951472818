import { amgApi } from "@/service/axios";

class UploadService {
  async upload(body) {
    try {
      const data = await amgApi.post("/config/upload", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async download(body) {
    try {
      const data = await amgApi.post("/config/download", body, {
        responseType: "blob",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new UploadService();
